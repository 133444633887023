import { TRAFFIC_ASSETS_QUERY } from "@/constants/traffic-asset-queries.js";
import {
  TAILBOARD_FORMS_QUERY,
  TAILBOARD_FORM_SUBMISSIONS_QUERY,
  ARCHIVED_TAILBOARD_FORM_SUBMISSIONS_QUERY
} from "@/constants/form-queries.js";
import {
  CURRENT_USER_QUERY,
  EMPLOYEES_QUERY,
  USERS_QUERY
} from "@/graphql/accounts/queries.js";
import {
  HAZARDS_QUERY,
  HAZARD_CATEGORIES_QUERY,
  BARRIERS_QUERY,
  WORK_TYPES_QUERY
} from "@/constants/section-queries.js";
import { workers } from "@/shared/workers.js";
import {
  VEHICLES_QUERY,
  FEEDERS_QUERY,
  STRUCTURES_QUERY
} from "@/graphql/external/queries.js";
import { CRITICAL_TASKS_QUERY } from "@/graphql/criticaltasks/queries.js";
import { apolloClient } from "@/plugins/apollo";
import dataAccess from "@/js/data-access.js";
import store from "@/plugins/store.js";
import getEnv from "@/utilities/env.js";
import { Tables } from "@/shared/consts.js";

export default {
  toGQL(entityName) {
    switch (entityName) {
      case "tailboardForms":
        return TAILBOARD_FORMS_QUERY;
      case "tailboardFormSubmissions":
        return TAILBOARD_FORM_SUBMISSIONS_QUERY;
      case "archivedTailboardFormSubmissions":
        return ARCHIVED_TAILBOARD_FORM_SUBMISSIONS_QUERY;
      case "hazards":
        return HAZARDS_QUERY;
      case "hazardCategories":
        return HAZARD_CATEGORIES_QUERY;
      case "barriers":
        return BARRIERS_QUERY;
      case "employees":
        return EMPLOYEES_QUERY;
      case "workTypes":
        return WORK_TYPES_QUERY;
      case "criticalTasks":
        return CRITICAL_TASKS_QUERY;
      case "vehicles":
        return VEHICLES_QUERY;
      case "users":
        return USERS_QUERY;
      case "user":
        return CURRENT_USER_QUERY;
      case "trafficAssets":
        return TRAFFIC_ASSETS_QUERY;
      case "feeders":
        return FEEDERS_QUERY;
      case "structures":
        return STRUCTURES_QUERY;
    }
  },

  isStoreItem(entity) {
    const noneStoreItems = [
      "tailboardFormSubmissions",
      "tailboardForms",
      "archivedTailboardFormSubmissions",
      "trafficAssets",
      "users",
      "feeders",
      "structures"
    ];
    return !noneStoreItems.includes(entity);
  },

  capitalize(s) {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  },

  async fetchAssets(trafficAssets) {
    let worker = workers.cacheWorker();
    worker.postMessage({
      type: "catch",
      files: trafficAssets,
      uri: `${getEnv("VUE_APP_SERVER_URL")}/doc/`
    });
  },

  async initializeEntity(entityName) {
    try {
      let response = await apolloClient.query({
        query: this.toGQL(entityName),
        fetchPolicy: "network-only"
      });
      const entity = response.data[entityName];
      const isSaved = await dataAccess.saveEntitiesAsync(
        entity.result || entity,
        entityName
      );
      if (isSaved) {
        if (this.isStoreItem(entityName))
          store.dispatch(`fetch${this.capitalize(entityName)}`);
        else {
          if (entityName === "trafficAssets") {
            await this.fetchAssets(entity.result || entity);
          }
        }
      }
      return isSaved;
    } catch (error) {
      return new Error(error).message.includes("status code 401");
    }
  },

  async initializeStoreAsync() {
    store.commit("setHazards", await this.getObjectStoreAsync("hazards"));
    store.commit(
      "setHazardCategories",
      await this.getObjectStoreAsync("hazardCategories")
    );
    store.commit("setBarriers", await this.getObjectStoreAsync("barriers"));
    store.commit("setWorkTypes", await this.getObjectStoreAsync("workTypes"));
    store.commit("setEmployees", await this.getObjectStoreAsync("employees"));
    store.commit(
      "setCriticalTasks",
      await this.getObjectStoreAsync("criticalTasks")
    );
    store.commit("setVehicles", await this.getObjectStoreAsync("vehicles"));
    store.commit("setRefreshView");
  },

  async getGQLResult(entityName) {
    try {
      let response = await apolloClient.query({
        query: this.toGQL(entityName),
        fetchPolicy: "network-only"
      });
      return response.data[entityName];
    } catch (e) {
      if (new Error(e).message.includes("status code 401")) {
        const url = `${getEnv("VUE_APP_SERVER_URL")}/login?redirect=${getEnv(
          "VUE_APP_SERVER_CALLBACK"
        )}`;
        if (
          !window.location.href.includes(
            `${getEnv("VUE_APP_SERVER_URL")}/login?redirect=`
          )
        )
          window.location = url;
      }
      return null;
    }
  },

  async logoutUserAsync() {
    if (await dataAccess.deleteAllAsync("updatedEntities")) {
      if (await dataAccess.deleteAllAsync("archivedTailboardFormSubmissions")) {
        if (await dataAccess.deleteAllAsync("tailboardFormSubmissions"))
          if (await dataAccess.deleteAllAsync(Tables.TAILBOARDS_QUEUE))
            return await dataAccess.deleteAllAsync("user");
      }
    }
    return false;
  },
  async updateUser() {
    let user = await this.getGQLResult("user");
    if (user) {
      const isSaved = await dataAccess.saveEntityByIdAsync(user, "user");
      if (isSaved) store.commit("setUser", user);
    }
  },
  async getUser() {
    let user = {};
    if (await dataAccess.isExistAsync("user")) {
      user = await dataAccess.getEntityAsync("user");
    }
    return user && Object.keys(user).length > 0 ? user : null;
  },

  async getObjectStoreAsync(entityName) {
    let entityObject = await dataAccess.getEntitiesAsync(entityName);
    return Object.keys(entityObject).length === 0 ? [] : entityObject;
  },

  async updateEntitiesVersion(updatedObjects) {
    try {
      store.commit("setIsBusy", true);
      const toCamel = s => {
        return s.toLowerCase().replace(/([-_][a-z])/gi, $1 => {
          return $1
            .toUpperCase()
            .replace("-", "")
            .replace("_", "");
        });
      };
      let refresh = false;
      const requiredRefresh = ["archivedTailboardFormSubmissions"];
      const objects = Object.entries(updatedObjects);
      for (const [key, value] of objects) {
        const entityKey = toCamel(key);
        const entity = await dataAccess.getEntityByIdAsync(
          "updatedEntities",
          entityKey
        );
        if (!entity.result || value !== entity.result) {
          const isSaved = await dataAccess.saveEntityAsync(
            entityKey,
            value,
            "updatedEntities"
          );
          if (isSaved) {
            refresh = refresh || requiredRefresh.includes(entityKey);
            await this.initializeEntity(entityKey);
          }
        }
      }
      if (refresh) store.commit("setRefreshView");
    } finally {
      store.commit("setIsBusy", false);
    }
  }
};
