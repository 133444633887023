export function getLockedRole(submission, user) {
  if (!submission || !user) return;

  return (
    submission.isLocked &&
    submission.isLockedBy &&
    submission.isLockedBy.id !== user.id &&
    !["MANAGER", "ADMIN"].includes(user.role)
  );
}

export function getReviewMode(submission, user) {
  if (!submission || !user) return;

  const isLockedRole = getLockedRole(submission, user);

  return (
    isLockedRole ||
    (submission.mode === "CLOSED" && submission.state === "SUBMITTED") ||
    submission.state === "ARCHIVED" ||
    user.id !== submission.user.id
  );
}

export function isClaimedBySomeone(submission) {
  const { claims } = submission;
  if (!Array.isArray(claims)) {
    return false;
  }
  return claims.length > 0;
}

export function isClaimedByMe(submission, user) {
  if (!isClaimedBySomeone(submission)) {
    return false;
  }
  const {
    claims: [claim]
  } = submission;
  return claim.userId === user.id;
}

export function isClaimedBySomeoneElse(submission, user) {
  return isClaimedBySomeone(submission) && !isClaimedByMe(submission, user);
}
