import { render, staticRenderFns } from "./dashboard-tab.vue?vue&type=template&id=2be3ed4f&scoped=true&"
import script from "./dashboard-tab.vue?vue&type=script&lang=js&"
export * from "./dashboard-tab.vue?vue&type=script&lang=js&"
import style0 from "./dashboard-tab.vue?vue&type=style&index=0&id=2be3ed4f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2be3ed4f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VBtn,VCol,VDataIterator,VDataTable,VIcon,VPagination,VRow,VSelect,VSpacer})
