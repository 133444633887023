import * as database from "@/js/database.js";

export const UsersMixin = {
  data() {
    return {
      users: []
    };
  },

  async created() {
    const handle = await database.open();
    const users = await database.fetchUsers(handle);

    this.users = users;
  }
};
