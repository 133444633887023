import gql from "graphql-tag";

import { TAILBOARD_FORM_SUBMISSION_FRAGMENT } from "./fragments.js";

export const CLAIM_TAILBOARD_FORM_SUBMISSION_MUTATION = gql`
  mutation claimTailboardFormSubmissionMutation(
    $tailboardFormSubmissionId: ID
  ) {
    claimTailboardFormSubmission(
      tailboardFormSubmissionId: $tailboardFormSubmissionId
    ) {
      messages {
        field
        code
        message
      }
      result {
        ...TailboardFormSubmissionFragment
      }
      successful
    }
  }
  ${TAILBOARD_FORM_SUBMISSION_FRAGMENT}
`;

export const RELEASE_TAILBOARD_FORM_SUBMISSION_MUTATION = gql`
  mutation releaseTailboardFormSubmissionMutation(
    $tailboardFormSubmissionId: ID
  ) {
    releaseTailboardFormSubmission(
      tailboardFormSubmissionId: $tailboardFormSubmissionId
    ) {
      messages {
        field
        code
        message
      }
      result {
        ...TailboardFormSubmissionFragment
      }
      successful
    }
  }
  ${TAILBOARD_FORM_SUBMISSION_FRAGMENT}
`;
