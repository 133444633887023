<template>
  <div class="div-top-line">
    <v-data-iterator
      v-if="formCase === 0"
      :items="activeTailboards"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :options="{
        itemsPerPageOptions: paginationOptions.map(x => x.value),
        pagination: pageCount,
        page: page
      }"
      hide-default-footer
      @page-count="pageCount = $event"
      no-data-text=""
      class="pt-2"
    >
      <template v-slot:no-data>
        <v-alert
          :value="true"
          color="secondary"
          class="caption primary--text"
          icon="information"
        >
          Sorry, no data available.
        </v-alert>
      </template>
      <template v-slot:default="props">
        <v-row no-gutters>
          <v-col
            v-for="tailboard in props.items"
            :key="tailboard.id"
            cols="12"
            sm="12"
            md="6"
            lg="6"
            style="position: relative"
          >
            <preview-slidebar
              @show="$emit('show', $event)"
              :submission="tailboard"
              :standAlone="true"
            />
            <submission-card
              :submission="tailboard"
              :users="users"
              @pdf="fetchPDF(tailboard.id)"
              @edit="editSubmission($event)"
              @show="$emit('show', tailboard.tailboardFormSubmissionReview)"
            />
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
    <v-data-table
      v-else
      :headers="headers"
      :items="activeTailboardsList"
      :items-per-page="itemsPerPage"
      hide-default-footer
      :page="page"
      :options="{
        itemsPerPageOptions: paginationOptions.map(x => x.value),
        pagination: pageCount,
        page: page
      }"
      class="elevation-1 table-theme"
      @page-count="pageCount = $event"
    >
      <template v-slot:item="row">
        <tr :class="row.item.type === 'TAILBOARD' ? 'white' : 'active'">
          <td>
            <preview-slidebar
              @show="$emit('show', $event)"
              :submission="row.item"
              :standAlone="false"
            />
          </td>
          <td class="project-name">
            {{ row.item.projectName }}
          </td>
          <td class="date-initiated">
            {{ row.item.insertedAt | parseDate }}
          </td>
          <td class="work-location">
            {{ row.item.location }}
          </td>
          <td class="onsite-leader">{{ row.item.user.name }}</td>
          <td class="work-order">
            {{ row.item.workOrderNumber }}
          </td>
          <td class="mode">{{ pascalCase(row.item.mode) }}</td>
          <td class="status">{{ pascalCase(row.item.state) }}</td>
          <td class="actions">
            <div class="d-flex">
              <v-btn
                :disabled="
                  row.item.type !== 'TAILBOARD'
                    ? row.item.user.id !== user.id
                    : false
                "
                icon
                @click="editSubmission(row.item)"
              >
                <v-icon size="40">$vuetify.icons.pdf</v-icon>
              </v-btn>

              <v-btn
                v-if="
                  row.item.state === 'SUBMITTED' ||
                    row.item.state === 'ARCHIVED'
                "
                icon
                :disabled="!row.item.id"
                @click="fetchPDF(row.item.id)"
              >
                <v-icon size="40">{{
                  row.item.id
                    ? "$vuetify.icons.download"
                    : "$vuetify.icons.disabledDownload"
                }}</v-icon>
              </v-btn>
              <v-btn
                icon
                v-if="user.role === 'SAFETY'"
                @click="incidentReportedConfirmation(row.item)"
              >
                <v-icon v-if="row.item.incidentReported"
                  >$vuetify.icons.alarm</v-icon
                >
                <v-icon v-else>$vuetify.icons.warning</v-icon>
              </v-btn>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-row class="text-center pt-2">
      <v-col cols="auto" class="d-flex pl-3 pt-3">
        <h5 class="mt-3 mr-1">Rows per Page:</h5>
        <v-select
          solo
          v-model="itemsPerPage"
          class="mt-1 pages-count"
          :items="paginationOptions"
          dense
        />
      </v-col>
      <v-spacer></v-spacer>
      <v-col>
        <v-pagination
          class="float-right"
          v-model="page"
          :length="pageCount"
        ></v-pagination>
      </v-col>
    </v-row>
    <dialog-message
      :showDialogMessage="showDialogMessage"
      displayCaption="Incident Report"
      :displayText="dialogText"
      :okAction="incidentReported"
      :cancelAction="cancelAction"
    />
  </div>
</template>
<script>
import submissionCard from "./submission-card.vue";
import { getReviewMode, isClaimedByMe } from "@/utilities/submission.js";
import utils from "@/shared/Utils";
import dayjs from "@/plugins/dayjs";
import previewSlidebar from "@/components/preview-slidebar.vue";
import dialogMessage from "@/components/submission/dialog-message.vue";
import getEnv from "@/utilities/env.js";
import * as database from "@/js/database.js";

export default {
  name: "DashboardTab",
  components: {
    submissionCard,
    previewSlidebar,
    dialogMessage
  },
  props: {
    formCase: Number,
    tailboardFormSubmissions: Array,
    submitIncidentReport: { type: Function }
  },
  filters: {
    parseDate(val) {
      return dayjs
        .utc(val)
        .local()
        .format("YYYY-MM-DD, HH:mm");
    }
  },
  mounted() {
    this.page = localStorage.getItem("page")
      ? parseInt(localStorage.getItem("page"))
      : 1;
    this.itemsPerPage = localStorage.getItem("itemsPerPage")
      ? parseInt(localStorage.getItem("itemsPerPage"))
      : 10;
    this.getUsers();
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      selectedSubmission: null,
      showDialogMessage: false,
      headers: [
        {
          text: "REVIEWED?",
          align: "start",
          sortable: false,
          class: "primary--text"
        },
        {
          text: "PROJECT NAME",
          align: "start",
          sortable: false,
          value: "projectName",
          class: "primary--text"
        },
        { text: "DATE INITIATED", value: "insertedAt", class: "primary--text" },
        {
          text: "LOCATION",
          value: "location",
          class: "primary--text"
        },
        { text: "ONSITE LEADER", value: "user.name", class: "primary--text" },
        {
          text: "WORK ORDER #",
          value: "workOrderNumber",
          class: "primary--text"
        },
        { text: "MODE", value: "mode", class: "primary--text" },
        { text: "STATUS", value: "state", class: "primary--text" },
        { text: "ACTIONS", value: "", sortable: false, class: "primary--text" }
      ],
      users: []
    };
  },
  computed: {
    dialogText() {
      return this.selectedSubmission && this.selectedSubmission.incidentReported
        ? "Are you sure? This action will unlock the form."
        : "Are you sure? This action will lock the form and hide it from all users except the users in the “Safety role.";
    },
    user() {
      return this.$store.getters.getUser;
    },
    activeTailboards() {
      return this.tailboardFormSubmissions || [];
    },
    activeTailboardsList() {
      let activeFormSubmissionTailboards = [];
      this.activeTailboards.forEach(submission => {
        activeFormSubmissionTailboards.push({
          projectName: submission.tailboardFormHeader.projectName,
          insertedAt: submission.insertedAt,
          location: submission.tailboardFormHeader.location,
          user: submission.user,
          department: submission.tailboardFormHeader.dept,
          workOrderNumber: submission.tailboardFormHeader.workOrderNumber,
          mode: submission.mode,
          claims: submission.claims,
          parentMode: null,
          state: submission.state,
          pdfFile: submission.pdfFile,
          id: submission.id,
          formId: submission.formId,
          isLocked: submission.isLocked,
          type: submission.tailboardForm.type,
          tailboardFormSubmissionReview:
            submission.tailboardFormSubmissionReview,
          midDayReviewed: submission.midDayReviewed,
          endDayReviewed: submission.endDayReviewed,
          hasCriticalTasks:
            submission.criticalTasksAssessment &&
            submission.criticalTasksAssessment.criticalTasksIds.length > 0,
          incidentReported: submission.incidentReported,
          incidentReportedAt: submission.incidentReportedAt
        });
        if (submission.childTailboardFormSubmissions) {
          submission.childTailboardFormSubmissions.forEach(child => {
            activeFormSubmissionTailboards.push({
              projectName: `Child : ${child.tailboardForm.title}`,
              insertedAt: child.insertedAt,
              location: submission.tailboardFormHeader.location,
              user: child.user,
              department: submission.tailboardFormHeader.dept,
              workOrderNumber: submission.tailboardFormHeader.workOrderNumber,
              mode: child.mode,
              parentMode: submission.mode,
              state: submission.state === "ARCHIVED" ? "ARCHIVED" : child.state,
              pdfFile: "",
              id: child.id,
              formId: child.formId,
              type: child.tailboardForm.type,
              review: {}
            });
          });
        }
      });

      activeFormSubmissionTailboards = activeFormSubmissionTailboards.sort(
        (a, b) => new Date(b.insertedAt) - new Date(a.insertedAt)
      );

      return activeFormSubmissionTailboards;
    },
    paginationOptions() {
      let range = [];

      // Set the list of forms based on view type - `formCase`: 0: grid, 1: table
      const formsList =
        this.formCase < 1
          ? this.tailboardFormSubmissions
          : this.activeTailboardsList;
      range.push({ text: "10", value: 10 });
      if (formsList) {
        if (formsList.length > 25) {
          range.push({ text: "25", value: 25 });
        }
      }

      return range;
    }
  },
  watch: {
    page(value) {
      localStorage.setItem("page", value);
    },
    itemsPerPage(value) {
      localStorage.setItem("itemsPerPage", value);
    },

    paginationOptions(val) {
      // Set default itemsPerPage so we don't set to 'All' when many forms
      //this.itemsPerPage = 10;
      // Set itemsPerPage if not already set and exists in list
      this.itemsPerPage =
        val.findIndex(x => x.value === this.itemsPerPage) < 0
          ? val[0].value
          : this.itemsPerPage;

      // Set page to 1 when viewing 'All'
      if (this.itemsPerPage === -1) this.page = 1;
    }
  },
  methods: {
    isSubmissionClaimedByMe(submission) {
      return isClaimedByMe(submission, this.user);
    },
    async incidentReported() {
      if (this.selectedSubmission.incidentReported) {
        this.selectedSubmission.incidentReported = false;
        this.selectedSubmission.incidentReportedAt = null;
        this.selectedSubmission.incidentReportedBy = null;
      } else {
        this.selectedSubmission.incidentReported = true;
        this.selectedSubmission.incidentReportedAt = dayjs.utc().format();
        this.selectedSubmission.incidentReportedBy = this.user;
      }
      await this.submitIncidentReport({
        id: this.selectedSubmission.id,
        incidentReported: this.selectedSubmission.incidentReported,
        incidentReportedAt: this.selectedSubmission.incidentReportedAt,
        incidentReportedBy: this.selectedSubmission.incidentReportedBy
      });
      // if (result)
      this.cancelAction();
    },
    displayComment(review) {
      if (review && review.comment) this.$emit("show", review);
    },
    incidentReportedConfirmation(submission) {
      this.selectedSubmission = submission;
      this.showDialogMessage = true;
    },
    cancelAction() {
      this.selectedSubmission = null;
      this.showDialogMessage = false;
    },
    pascalCase(str) {
      return utils.toPascalCase(str);
    },
    async fetchPDF(pdfFile) {
      const url = `${getEnv("VUE_APP_SERVER_URL")}/pdf/${pdfFile}`;
      await utils.deleteCachedData("tailboard-submissions-files", url);

      let request = new XMLHttpRequest();
      request.open("GET", url, true);
      request.responseType = "blob";
      request.onload = function() {
        //Convert the Byte Data to BLOB object.
        const blob = new Blob([request.response], {
          type: "application/octetstream"
        });
        //Check the Browser type and download the File.
        const isIE = false || !!document.documentMode;
        if (isIE) {
          window.navigator.msSaveBlob(blob, `${pdfFile}.pdf`);
        } else {
          const link = URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.setAttribute("download", `${pdfFile}.pdf`);
          a.setAttribute("href", link);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      };
      request.send();

      // window.open(`${getEnv("VUE_APP_SERVER_URL")}/pdf/${pdfFile}`, "_blank");
    },
    editSubmission(submission) {
      const reviewMode = getReviewMode(submission, this.user);

      if (
        submission.parentMode === "CLOSED" ||
        reviewMode ||
        !this.isSubmissionClaimedByMe(submission)
      )
        this.$router.push(`/preview/${submission.id}/${submission.state}`);
      else
        this.$router.push(
          `/forms/${submission.formId}/submit/${submission.id}`
        );
    },
    async getUsers() {
      const handle = await database.open();
      this.users = await database.fetchUsers(handle);
    }
  }
};
</script>
<style scoped>
.hide-download {
  visibility: hidden;
}

.table-theme table tr td {
  max-width: 250px !important;
}

table td {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.actions button:first-child:disabled .v-icon {
  filter: brightness(300%) grayscale(100%);
}

.project-name {
  min-width: 130px;
}

.date-initiated {
  min-width: 130px;
}

.work-location {
  min-width: 150px;
}

.onsite-leader {
  min-width: 150px;
}

.work-order {
  min-width: 151px;
}

.mode {
  min-width: 90px;
}

.status {
  min-width: 100px;
}

.actions {
  min-width: 248px;
}
</style>
