import getEnv from "@/utilities/env.js";
import { Tables } from "@/shared/consts.js";

async function open() {
  const name = getEnv("VUE_APP_LOCAL_DATABASE");
  const version = getEnv("VUE_APP_LOCAL_DATABASE_VERSION");

  return new Promise(resolve => {
    const request = window.indexedDB.open(name, version);
    request.addEventListener("success", ({ target: { result } }) => {
      resolve(result);
    });
  });
}

function saveSubmission(handle, submission) {
  const table = Tables.TAILBOARD_FORM_SUBMISSIONS;

  const transaction = handle.transaction([table], "readwrite");

  const store = transaction.objectStore(table);

  store.put(submission, submission.id);

  return submission;
}

async function fetchUsers(handle) {
  const table = Tables.USERS;
  const transaction = handle.transaction([table], "readonly");

  const store = transaction.objectStore(table);
  const request = store.openCursor();

  return new Promise(resolve => {
    const users = [];

    request.addEventListener("success", ({ target: { result } }) => {
      if (result) {
        users.push(result.value);
        result.continue();
      } else {
        resolve(users);
      }
    });
  });
}

export { open, saveSubmission, fetchUsers };
