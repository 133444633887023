import tailboardController from "@/js/tailboards-controller.js";
import { isClaimedByMe, isClaimedBySomeone } from "@/utilities/submission.js";

export const ClaimsMixin = {
  computed: {
    claimsSubmission() {
      const submission = this.tailboardFormSubmission;
      if (!submission) {
        return null;
      }
      const parent = submission.parentTailboardFormSubmission;
      if (parent) {
        return parent;
      } else {
        return submission;
      }
    },
    isClaimedBySomeone() {
      const submission = this.claimsSubmission;
      if (!submission) {
        return false;
      }
      return isClaimedBySomeone(submission);
    },
    isClaimedByMe() {
      const submission = this.claimsSubmission;
      if (!this.user || !submission) {
        return false;
      }
      return isClaimedByMe(submission, this.user);
    },
    isClaimedBySomeoneElse() {
      return this.isClaimedBySomeone && !this.isClaimedByMe;
    },
    claimedBy() {
      if (this.isClaimedByMe) {
        return this.user.email;
      } else if (this.isClaimedBySomeoneElse) {
        const {
          claims: [{ userId }]
        } = this.claimsSubmission;
        const user = this.users.find(u => u.id === userId);
        if (user) {
          return user.email;
        } else {
          return "unknown";
        }
      } else {
        return "nobody";
      }
    }
  },
  methods: {
    async claimTailboardFormSubmission() {
      if (!this.isOnline) {
        this.showUserMessage(
          "You are not connected to the server, and as such cannot claim this submission.",
          "error"
        );
      } else if (this.isClaimedBySomeoneElse) {
        this.showUserMessage(
          `This submission is already claimed by ${this.claimedBy}`,
          "error"
        );
      } else if (this.isClaimedByMe) {
        this.showUserMessage("This submission is already claimed by you.");
      } else {
        try {
          const updatedSubmission = await tailboardController.claimTailboardFormSubmission(
            this.tailboardFormSubmission.id
          );
          this.tailboardFormSubmission = updatedSubmission;
          this.showUserMessage("You have claimed this form submission.");
          if (this.onSubmissionClaimed) {
            this.onSubmissionClaimed();
          }
        } catch (errorId) {
          if (typeof errorId === "string") {
            this.showUserMessage(
              `An error occurred trying to claim this form submission, please contact support with error id '${errorId}'.`,
              "error"
            );
          } else {
            this.showUserMessage(
              "an error occurred trying to claim this form submission.",
              "error"
            );
          }
        }
      }
    },
    async releaseTailboardFormSubmission() {
      try {
        const updatedSubmission = await tailboardController.releaseTailboardFormSubmission(
          this.tailboardFormSubmission.id
        );
        this.tailboardFormSubmission = updatedSubmission;
        this.showUserMessage("You have released this form submission.");

        if (this.onSubmissionReleased) {
          this.onSubmissionReleased();
        }
      } catch (errorId) {
        if (typeof errorId === "string") {
          this.showUserMessage(
            `An error occurred trying to claim this form submission, please contact support with error id '${errorId}'.`,
            "error"
          );
        } else {
          this.showUserMessage(
            "an error occurred trying to claim this form submission.",
            "error"
          );
        }
      }
    }
  }
};
