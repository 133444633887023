import gql from "graphql-tag";

import {
  EMPLOYEE_ENTITY_FRAGMENT,
  USER_ENTITY_FRAGMENT
} from "@/graphql/accounts/fragments.js";

export const TAILBOARD_FORM_FRAGMENT = gql`
  fragment TailboardFormFragment on TailboardForm {
    header
    id
    instructions
    logoFile
    title
    type
  }
`;

export const PARENT_TAILBOARD_FORM_SUBMISSION_FRAGMENT = gql`
  fragment ParentTailboardFormSubmissionFragment on TailboardFormSubmission {
    formId
    id
    state
    insertedAt
    claims {
      userId
      tailboardFormSubmissionId
    }
    tailboardForm {
      ...TailboardFormFragment
    }
  }
  ${TAILBOARD_FORM_FRAGMENT}
`;

export const TAILBOARD_FORM_HEADER_FRAGMENT = gql`
  fragment TailboardFormHeaderFragment on TailboardFormHeader {
    esrr
    date
    dept
    id
    location
    projectName
    rcNumber
    wbs
    workDescription
    workOrderNumber
    fieldActivityId
  }
`;

export const TAILBOARD_FORM_SUBMISSION_REVIEW_FRAGMENT = gql`
  fragment TailboardFormSubmissionReviewFragment on TailboardFormSubmissionReview {
    comment
    id
    insertedAt
    updatedAt
    user {
      name
      role
      id
    }
  }
`;

export const TAILBOARD_FORM_SUBMISSION_FRAGMENT = gql`
  fragment TailboardFormSubmissionFragment on TailboardFormSubmission {
    id
    formId
    offline
    isLocked
    updatedAt
    updatedBy
    supervisorId
    supervisorPhone
    crewLeaderId
    crewLeaderPhone
    endDayReviewed
    endDayReviewedAt
    endDayReviewedByName
    midDayReviewed
    midDayReviewedAt
    midDayReviewedByName
    hasCriticalTasks
    incidentReported
    incidentReportedAt
    claims {
      tailboardFormSubmissionId
      userId
    }
    incidentReportedBy {
      name
      id
      role
    }
    isLockedBy {
      ...UserEntity
    }
    employeeEntryLogs {
      componentId
      sectionId
      employeeId
      id
      timeIn
      timeOut
    }
    formVersion
    inapplicableSections
    inapplicableComponents
    visitors
    crewMembers {
      id
      vehicle {
        id
        isTrailer
        vehicleRef
      }
      trailer {
        id
        isTrailer
        vehicleRef
      }
      employee {
        ...EmployeeEntity
      }
    }
    employeeVisitors {
      visitDetails
      employeeId
      employee {
        ...EmployeeEntity
      }
    }
    inputFieldData {
      id
      inputFieldId
      value
    }
    signatures {
      id
      name
      file
      signedAt
      submissionId
      phase
      mode
      employee {
        ...EmployeeEntity
      }
    }
    insertedAt
    mode
    pdfFile
    state
    user {
      ...UserEntity
    }
    riskAssessment {
      id
      tailboardFormSubmissionId
      workType {
        id
        name
      }
      hazardIds
      jobSteps {
        id
        name
        step
        employeeIds
        jobStepHazards {
          id
          source
          position
          barriers
          hazardId
        }
      }
    }
    confinedSpaceSubstanceTests {
      bottomCo
      bottomH2s
      bottomLel
      bottomO2
      bottomOther
      componentId
      sectionId
      id
      midCo
      midH2s
      midLel
      midO2
      midOther
      time
      topCo
      topH2s
      topLel
      topO2
      topOther
    }
    cableChamberFeeders {
      comments
      componentId
      sectionId
      deltaS
      direction
      ductNumber
      feederDesignation
      hazards
      id
      maxTemp
      spotTemp
    }
    criticalTasksAssessment {
      criticalTasksIds
      id
    }
    childTailboardFormSubmissions {
      formId
      id
      insertedAt
      mode
      state
      isLocked
      isLockedBy {
        id
        name
      }
      user {
        ...UserEntity
      }
      tailboardForm {
        ...TailboardFormFragment
      }
    }
    tailboardFormHeader {
      ...TailboardFormHeaderFragment
    }
    tailboardForm {
      ...TailboardFormFragment
    }
    parentTailboardFormSubmission {
      ...ParentTailboardFormSubmissionFragment
    }
    tailboardFormSubmissionReview {
      ...TailboardFormSubmissionReviewFragment
    }
  }
  ${EMPLOYEE_ENTITY_FRAGMENT}
  ${USER_ENTITY_FRAGMENT}
  ${TAILBOARD_FORM_FRAGMENT}
  ${TAILBOARD_FORM_HEADER_FRAGMENT}
  ${PARENT_TAILBOARD_FORM_SUBMISSION_FRAGMENT}
  ${TAILBOARD_FORM_SUBMISSION_REVIEW_FRAGMENT}
`;
